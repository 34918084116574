import React, { useState, useEffect } from 'react';
import Account from '../account/Account';
import { useNavigate, useLocation } from 'react-router-dom';

// Image Import
import Cover from '../../assets/images/LearnAndEarn/main-fram-point.png'
import Smallicon from '../../assets/images/LearnAndEarn/reskillroundimage.png'
import ticket from '../../assets/images/LearnAndEarn/ticket-02.png'
import share from '../../assets/images/LearnAndEarn/Icon.png'
import star from '../../assets/images/LearnAndEarn/star.png'
import book from '../../assets/images/LearnAndEarn/book-open-02.png'

// Import Css

import '../../assets/scss/pages/learnAndEarn/learnAndEarn.scss'
import ButtonWithArrow from '../../components/common/button/ButtonWithArrow';
import Button from '../../components/common/button/Button';
import ShareModal from '../../components/Modals/ShareModal';

//
import { subscriptionStatusLearnAndEarn } from '../../services/learnAndEarn.service';
import { useDispatch, useSelector } from "react-redux"
import { setLearnAndEarnSubscriptionDetail, selectLearnAndEarnSubscriptionDetail } from '../../redux/learnAndEarnSubscriptionSlice';


const LearnAndEarnTotalPoints = () => {

    // const [subscriptionDetail , setSubscriptionDetail]=useState('')
    const [shareModal, setShareModal] = useState(false);
    // const [show , setShow] = useState(false)
    // const onHide=()=>{
    //     setShow(!show)
    // }
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch();
    const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail)
    const fetchUserSubscriptionStatus = async () => {
        const result = await subscriptionStatusLearnAndEarn();
        dispatch(setLearnAndEarnSubscriptionDetail(result?.data));
    };


    useEffect(() => {
        fetchUserSubscriptionStatus();
        // eslint-disable-next-line
    }, []);
    const handleShareModal = () => {
        setShareModal(!shareModal)
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');

        if (status === 'failure') {
            navigate('/account/learn-and-earn');
        }
    }, [location, navigate]);

    return (
        <Account>
            <div className='tran-container d-flex  justify-content-center'>
                <div className='main-tr-container'>
                    <h4 className='main-title fw-bold'>ReSkills Learn & Earn Program</h4>
                    <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 bg-card-primary'>
                        <img src={Smallicon} style={{ height: "30px", width: "30px" }} alt='logo' />
                        <div className='fw-semibold mx-2 text-white'>Total Earning Points (1 point = $1)</div>
                        <div className='mx-2 d-flex justify-content-between'>
                            <div className='fs-4 fw-bold text-white'>{ Math.round(subscriptionDetail?.total_points * 100) / 100 || 0}</div>

                            {/* {subscriptionDetail?.total_points>= 30 &&
                            <div className='sub-btn-l' >
                                <ButtonWithArrow
                                    name="Claim Reward"
                                    type="whitebtn"
                                    
                                />
                                    
                                
                            </div>} */}

                        </div>
                    </div>

                    <div className='shadow-sm rounded border border-light w-100 main-banner'>
                        <a href="http://About.reskills.com/learn-and-earn-program-details/" target="_blank" rel="noopener noreferrer">
                            <img src={Cover} className='img-fluid' style={{ maxHeight: '280px' }} alt="Learn and Earn Program Details" />
                        </a>
                    </div>
                    <div className='shadow-sm rounded my-3 border border-light  p-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='fw-semibold text-primary'><img src={ticket} className='mx-1' alt='ticket' style={{ height: "20px", marginBottom: "2px" }} />Referral Rewards</div>
                            <div className='fw-semibold text-primary'>{subscriptionDetail?.referral_count} Referrals</div>
                        </div>
                        <small className='text-muted mx-1'>For each successful referral with Learn & Earn subscription, you get 10% of the value in points. </small>
                        <div className='bg-muted rounded my-3'>
                            <div className='fw-semibold fw-semibold'>Be an inspiration to your friends! Invite them to Learn and Earn with ReSkills now!</div>
                            <div className='my-3 d-flex justify-content-between'>
                                <div className='fw-semibold text-primary'>{subscriptionDetail?.referral_code_url}</div>
                                <div onClick={handleShareModal}>
                                    <Button
                                        name={"Share"}
                                        icon={share}

                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='shadow-sm rounded my-3 border border-light  p-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='fw-semibold text-primary'><img src={book} className='mx-1' alt='ticket' style={{ height: "20px", marginBottom: "2px" }} />Learning Rewards</div>
                            <div className='fw-semibold text-primary'>{subscriptionDetail?.points_from_assessment} / 100 points</div>
                        </div>
                        <small className='text-muted mx-1'>For each onLive certificate you successfully complete, you earn 1 points. (max of 100 Points) </small>
                    </div>
                    <div className='shadow-sm rounded my-3 border border-light  p-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='fw-semibold  text-muted '><img src={star} className='mx-1' alt='ticket' style={{ height: "20px", marginBottom: "2px" }} />Mission Rewards</div>

                        </div>
                        <small className='text-muted mx-1'>Stayed tuned to upcoming missions to earn even MORE points! </small>
                    </div>



                </div>
            </div>
            <ShareModal show={shareModal} handleClose={handleShareModal} shareUrl={subscriptionDetail?.referral_code_url} />
        </Account>
    );
};

export default LearnAndEarnTotalPoints;