//styles
import classNames from "classnames";
import "../../../assets/scss/components/button/button.scss";

const Button = ({ name, type, btnType, onClick, icon, disable }) => {
  return (
    <div>
      <button
        onClick={onClick}
        type={type}
        className={classNames({
          "btn btn-outline-secondary bg-transparent btn-sm py-2 px-3 rounded fw-bold w-100 min-btn-w": btnType === "whiteButton",
          "btn btn-warning btn-sm p-2 rounded text-white w-100": btnType === "orangeButton",
          "btn btn-outline-secondary bg-white btn-sm py-2 px-3 rounded fw-bold w-100": btnType === "white",
          "primary-color-btn btn btn-primary btn-sm p-2 px-5 shadow-sm fw-bold rounded w-100": btnType === "registerNow",
          "primary-color-btn btn btn-red btn-sm p-2 shadow-sm fw-bold text-white rounded w-100 px-3": btnType === 'redeem',
          "btn btn-secondary btn-sm p-2 shadow-sm fw-bold rounded w-100 my-3": btnType === 'classEnded',
          "primary-color-btn btn btn-primary btn-sm p-2 shadow-sm fw-bold rounded w-100": btnType !== "whiteButton" && btnType !== "orangeButton" && btnType !== "white" && btnType !== "registerNow" && btnType !== 'redeem' && btnType !== 'classEnded',
          'd-flex align-items-center': icon
        })
        }
        disabled={disable}
      >
        {icon && (
          <span className={classNames({
            'me-1': icon && name
          })}>
            <img src={icon} alt="icon" />
          </span>
        )}{" "}
        {name}
      </button>
    </div>
  );
};

export default Button;
