import { useEffect } from "react";
import Account from "../account/Account";
import badge from "../../assets/images/subscriptionDetail/Badge.svg"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectSubscriptionDetail, setSubscriptionDetail } from "../../redux/subscriptionSlice";
import label from "../../assets/images/subscriptionDetail/Label.svg"
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";

//importing styles
import "../../assets/scss/components/subscriptionDetail/subscriptionDetail.scss"
import { getSubscriptionStatus } from "../../services/subscription.service";

const SubscriptionDetail = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const subcription = useSelector(selectSubscriptionDetail)

    const fetchUserSubscriptionStatus = async () => {
        const result = await getSubscriptionStatus();
        dispatch(setSubscriptionDetail(result?.data));
    };

    useEffect(() => {
        fetchUserSubscriptionStatus();
        // eslint-disable-next-line
    }, []);

    return (
        <Account>
            <div className="w-100">
                <h3 className="fw-bold">Subscription</h3>
                <div className="d-md-flex gap-3">
                    <div className="px-3 pt-3 bg-white border rounded w-md-50 w-100 ">
                        <div className="mb-2">

                            <p className="fs-6">Your Current Subscription Plan</p>
                            {
                                subcription?.status !== "inactive" ?

                                    <div className="d-flex justify-content-between">

                                        <h3 className="text-primary fw-bold">Premium</h3>
                                        <img src={badge} alt="icon" />
                                    </div>
                                    :
                                    <div className="text-primary text-center"><img src={label} alt="icon" /></div>
                            }
                        </div>
                    </div>
                    <div className="p-3 bg-white border rounded w-md-50 w-100">
                        <p className="fs-6">Valid Duration</p>
                        {
                            subcription?.status !== "inactive" ?

                                <h3 className="text-primary fw-bold">{subcription?.days_remaining} days</h3>
                                :
                                <div className="text-primary text-center"><img src={label} alt="icon" /></div>

                        }
                    </div>
                </div>
                {
                    subcription?.status !== "inactive" && subcription?.days_remaining > 60 ?

                        <div className="d-sm-flex align-items-start justify-content-between border p-3 mt-3 mb-5 rounded">
                            <div className="">
                                <h3 className="text-primary fw-bold">Yearly</h3>
                                <p className="fs-6"> Yearly Learning Package</p>
                            </div>
                            {
                                subcription?.amount &&

                                <h3 className="text-primary fw-bold">${subcription?.amount}</h3>
                            }
                            <button className="bg-success border-0 p-2 rounded text-white">Activated</button>
                        </div>
                        :
                        subcription?.status !== "inactive" && subcription?.days_remaining <= 60 ?
                            <div className="mt-3 mb-5">
                                <p className="fs-18 fw-bold text-center">Your account subscription is ending soon. Please resubscribe to extend your plan </p>
                                <div className="below-container mt-3 mx-md-auto w-25" onClick={() => navigate("/subscription")}>
                                    <ButtonWithArrow name="Subscribe Now" />
                                </div>
                            </div>
                            :
                            <div className="below-container mt-3  mx-md-auto mb-5 w-100 d-flex justify-content-center flex-column">
                                <p className="fs-4 fw-bold text-center">You haven't subscribed any plans yet!</p>
                                <div className="d-flex justify-content-center">
                                    <div className="mt-3 w-25 " onClick={() => navigate("/subscription")}>
                                        <ButtonWithArrow name="Subscribe Now" />
                                    </div>
                                </div>
                            </div>
                }
                {/* <h3 className="fw-bold">Learn and Earn</h3>

                <div className="d-sm-flex align-items-start justify-content-between border p-3 mt-3 mb-5 rounded">
                    <div className="">
                        <h3 className="text-primary fw-bold">200</h3>
                            <p className="fs-6"> Remaining Days</p>
                        </div>
                            <h3 className="text-primary fw-bold"> $1</h3>
                            <button className="bg-success border-0 p-2 rounded text-white">Activated</button>
                        </div> */}
            </div>
        </Account>
    );
};

export default SubscriptionDetail;