import { useState } from "react";
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

//importing components
import Button from "../../components/common/button/Button";
import UpdateEmailModal from "../../components/Modals/UpdateEmailModal";

//importing styles
import "../../assets/scss/pages/auth/otp/otpPage.scss";

//importing images
import icon from "../../assets/images/Auth/OtpIcon.svg";
import backIcon from "../../assets/images/Auth/BackIcon.svg";
import { setUserSession } from "../../utils/Auth.Service";
import { OTPValidation, resendOtp, VerifyOtpUpdateEmail } from "../../services/auth.service";
import { setToken } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

const OtpPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { control, watch, formState: { errors } } = useForm({ mode: "onBlur" });
    const watchOtp = watch("otp")

    const [isLoading, setIsLoading] = useState({
        verifyEmail: false,
        ClicktoResend: false,
    });
    const [wrongOtp, setWrongOtp] = useState(false);
    const [expiredOtp, setExpiredOtp] = useState(false);
    const [resendOtpError, setResendOtpError] = useState();
    const [remainResendAttempt, setRemainResendAttempt] = useState();
    const [show, setShow] = useState(false)


    const onHide = () => {
        setShow(!show)
    }


    /**
        * @function handleSubmit
        * @param (e)
        * @description This function is used to verify otp
    */



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading({ verifyEmail: true });

        let result
        if (state?.from === 'verify-otp') {
            result = await VerifyOtpUpdateEmail({
                email: state?.email,
                otp: watchOtp,
                verification_token: state?.verification_token
            });
        } else {
            result = await OTPValidation({
                email: state?.email,
                otp_type: state?.type,
                otp: watchOtp,
            })
        }


        if (result?.data?.message && result?.data?.status === "failed") {
            toast.error(result?.data?.message || "Something went wrong")
        } else if (result?.response?.data?.message) {
            toast.error(result?.response?.data?.message || "Something went wrong")
        }
        if (result?.data?.status === "success") {
            if (state?.type === "forget_password") {
                navigate("/reset-password", {
                    state: {
                        email: state?.email,
                        otp: watchOtp,
                    },
                });
            } else if (state?.type === "register") {
                const tokens = {
                    tokenAccess: result?.data?.access_token,
                };
                const user = result?.data?.user;
                dispatch(setToken(tokens?.tokenAccess))
                setUserSession(tokens, user);
                navigate("/profession-selection");
            } else if (state?.from === 'verify-otp') {
                const tokens = {
                    tokenAccess: result?.data?.access_token,
                };
                const user = result?.data?.user;
                dispatch(setToken(tokens?.tokenAccess))
                setUserSession(tokens, user);
                navigate("/profession-selection");
            }

            else {
                navigate("/");
            }
        } else if (result?.data?.message === "Invalid OTP") {
            setWrongOtp(true);
            setExpiredOtp(false);
        } else if (result?.data?.message === "OTP has expired") {
            setExpiredOtp(true);
        }
        setIsLoading({ verifyEmail: false });
    };

    /**
        * @function handleResendOtp
        * @param 
        * @description This function is used to resend password
    */
    const handleResendOtp = async () => {
        setIsLoading({ ClicktoResend: true });
        const result = await resendOtp({
            email: state.email,
            otp: state?.otp,
        });
        if (result?.response?.data?.message) {
            setResendOtpError(result?.response?.data?.message);
        }
        if (result?.data?.status === "success") {
            setRemainResendAttempt(5 - result?.data?.user?.otp_resend_count);
        }
        setIsLoading({ ClicktoResend: false });
        setExpiredOtp(false);

    };

    return (
        <div className="otpPage-main-container">
            <div className="text-center">
                <img src={icon} alt="icon" className="mb-4" />
                <h2 className="fw-bold fs-4 mb-2">Check your email</h2>
                {/* <p className="text-secondary">Congratulations! Your ReSkills account has been successfully created. Final step: Verify your email. </p> */}
                <p className="text-secondary my-3"> Please enter the verification code that we sent <br /> to {state?.email} here below.</p>

            </div>
            <Controller
                control={control}
                name={`otp`}
                render={({ field }) => (
                    <OtpInput
                        containerStyle='verifiction-input-div justify-content-between mb-3'
                        inputStyle='verifiction-input form-control rounded-2'
                        focusStyle='border-0'
                        value={field.value}
                        onChange={field.onChange}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                    />
                )}
                rules={{ required: 'Please enter your 4 digit OTP' }}
            />
            {errors.otp && <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                {errors.otp}
            </p>}
            {wrongOtp && (
                <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                    Incorrect code
                </p>
            )}
            {expiredOtp && (
                <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                    Otp expired
                </p>
            )}
            {remainResendAttempt && (
                <p style={{ fontSize: "13px", color: "green", margin: "0" }}>
                    {remainResendAttempt} resend otp attempt left.
                </p>
            )}
            {resendOtpError && (
                <p style={{ fontSize: "13px", color: "#f04438", margin: "0" }}>
                    {resendOtpError}
                </p>
            )}
            <div onClick={(e) => handleSubmit(e)} className="mt-4">
                <Button
                    name={`${isLoading?.verifyEmail ? "Loading..." : "Verify email"}`}
                />
            </div>
            <div
                className="mt-3 text-center cursor-pointer"
                onClick={() => handleResendOtp()}
            >
                <p className="text-secondary fs-6">
                    Didn't received the email?{" "}
                    <span className="text-primary fw-bold">
                        {isLoading?.ClicktoResend ? "Loading..." : "Click to resend"}
                    </span>
                </p>
            </div>
            {state?.from === 'sign-in-update' &&
                <div
                    className="mt-3 text-center cursor-pointer"
                    onClick={() => { setShow(true) }}
                >
                    <p className="text-secondary fs-6">
                        Invalid email?{" "}
                        <span className="text-primary fw-bold">
                            Click to update email
                        </span>
                    </p>
                </div>}
            <div
                className="justify-content-center d-flex align-item-center gap-2 cursor-pointer"
                onClick={() => navigate("/login")}
            >
                <img src={backIcon} alt="backIcon" />
                <p className="m-0 fs-6 fw-bold">Back to Login</p>
            </div>

            <UpdateEmailModal
                show={show}
                onHide={onHide}
                first_name={state?.first_name}
                verification_token={state?.verificationToken}
            />
        </div>
    );
};

export default OtpPage;
