import React from 'react';
import Account from '../account/Account';
import { useState } from 'react';

// Image Import

import Cover from '../../assets/images/LearnAndEarn/main-fram.png'
import BigCover from '../../assets/images/LearnAndEarn/big-main.png'

// Import Css

import '../../assets/scss/pages/learnAndEarn/learnAndEarn.scss'
import ButtonWithArrow from '../../components/common/button/ButtonWithArrow';


import SummaryModal from '../../components/Modals/RateAndReviewModals/SummaryModal';


const LearnAndEarnSubscribe = () => {
    const [show, setShow] = useState(false)
    const [activeModal, setActiveModal] = useState('subscribe')

    const onHide = () => {
        setShow(!show)
        setActiveModal('subscribe')
    }


    return (
        <Account>
            <div className='tran-container d-flex  justify-content-center'>
                <div className='main-tr-container'>
                    <h4 className='main-title fw-bold'>ReSkills Learn & Earn Program</h4>
                    <div className='shadow-sm rounded   border border-light  w-100 main-banner '>
                        <img src={Cover} className='img-fluid' style={{ maxHeight: '280px' }} alt='cover' />
                    </div>
                    <div className='shadow-sm rounded my-3 border border-light d-flex justify-content-center align-items-center my-4 flex-column p-3'>
                        {/* <img src={Smallicon} className='my-4' style={{width:"200px"}}/> */}
                        <h4 className='fw-bold text-center'>You Haven't Entered This Program</h4>
                        <h5 className='fw-bold text-primary text-center'>How to enter learn and earn program ?</h5>
                        <p className='  fw-semibold text-center'>Subscribe 2 years of ReSkills Premium Account<br />At $24 (+$2 service charge fee)<span className='text-primary'>= $26</span> </p>

                        <div className='sub-btn-l' onClick={() => setShow(!show)}>
                            <ButtonWithArrow
                                name="Subscribe Now"
                            />
                        </div>


                        {/* {activeModal==='subscribe' && 
                        <SubscribeModal
                            show={show}
                            onHide={onHide}
                            setActiveModal={setActiveModal}
                        />} */}
                        {(activeModal === 'subscribe') &&
                            <SummaryModal
                                show={show}
                                onHide={onHide}
                                activeModal={activeModal}

                            />}

                    </div>
                    <div className='shadow-sm rounded   border border-light  w-100 main-banner '>
                        <img src={BigCover} className='img-fluid' alt='cover' />
                    </div>

                </div>
            </div>
        </Account>
    );
};

export default LearnAndEarnSubscribe;