import React, { useEffect, useState } from 'react';
import '../../assets/scss/pages/TransactionHistory/TransactionHistory.scss'
import Account from '../account/Account';
import { transactionHistoryService } from '../../services/transaction.service';
// import moment from 'moment';
import NoTransactionHistory from './NoTransactionHistory';
// import TransactionHistoryCard from '../../components/common/cards/TransactionHistory/TransactionHistoryCard';

import CardPaginate from '../../components/common/paginate/CardPaginate';

const TransactionHistory = () => {
    const [transactionHistoryList, setTransactionHistoryList] = useState([]);

    const fetchTransactionHistoryList = async () => {
        const result = await transactionHistoryService();
        setTransactionHistoryList(result?.data?.data?.data);
    };

    useEffect(() => {
        fetchTransactionHistoryList();
    }, []);

    return <Account>
        <div className='tran-container d-flex  justify-content-center'>
            <div className='main-tr-container'>
                <h4 className='main-title'>Transaction History</h4>
                {transactionHistoryList?.length > 0 ?
                    // transactionHistoryList?.map((item, index) => {
                    //     return(
                    //         <TransactionHistoryCard
                    //         item={item}
                    //         index={index}
                    //     />
                    //     )

                    // }) 

                    <CardPaginate items={transactionHistoryList} totalItem={8} />
                    : <NoTransactionHistory />
                }
            </div>
        </div>
    </Account>
};

export default TransactionHistory;