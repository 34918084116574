import React from "react";
//componets
import Button from "../../button/Button";
//images
import cover from "../../../../assets/images/Certificate/Frame 15.png";
import Btn from "../../../../assets/images/Certificate/Icon.png";
import avatar from "../../../../assets/images/Cards/HorizentalCard/Avatar.svg";
//css
import "../../../../assets/scss/pages/certificateCard/certificateCard.scss";
//skeleton
import Skeleton from "react-loading-skeleton";



const CertificateCard = ({ cardDetail }) => {

  

  const download = () => {
    let url = cardDetail?.pdf;
    console.log(url , 'url-my')
    
    if (url) {
      // Modify the URL if "public/users" is not present
      if (!url?.includes("public/users")) {
        url = url?.replace("public/", "public/micro-cert/");
      }

      console.log(url , 'url-my')
  
      fetch(url)?.then(response => {
        response?.blob()?.then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = url?.split("/")[url?.split("/")?.length - 1];
          alink.click();
        });
      });
    }
  };

  return (
    <div className=" shadow-sm rounded w-100  cursor-pointer  py-3 px-2"  >
      <div className="d-flex justify-content-between align-cardDetails-center mt-3 border-bottom py-2">
        <div className="row g-0 w-100">
          <div className="col-xl-5 p-1">
            <div className="d-flex">

              {cardDetail?.class_image_url ?
                <img
                  src={cardDetail?.class_image_url}
                  alt="profile"
                  className=" rounded small-img"
                  style={{ height: "170px" }}
                /> :
                <div className="w-100 ">
                  <Skeleton height={150} />
                </div>

              }

              <div className="hide-on-medium  mx-2">
                {cardDetail?.serial_number ? <div className="d-flex">
                  <small>Serial Number</small>
                  <p className="fw-bold mx-1">{cardDetail?.serial_number}</p>
                </div> : <Skeleton width={170} />}
                {cardDetail?.class_type_name ? <div className="bg-primary text-white rounded px-3 online-btn">
                  {cardDetail?.class_type_name}
                </div> : <Skeleton />}
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <div>
              <div className="d-none d-sm-block">
                {cardDetail ?
                  <div className="d-flex justify-content-between gap-2   ">
                    <div className="d-flex">
                      <small>Serial Number</small>
                      <p className="fw-bold mx-1">{cardDetail?.serial_number}</p>
                    </div>
                    <div className="bg-primary-btn text-white rounded px-3 online-btn">
                      {cardDetail?.class_type_name}
                    </div>
                  </div> :
                  <Skeleton />
                }
              </div>
              <div >
                <a className="mb-0 text-primary" >
                  <h5>
                    {cardDetail?.class_title || <Skeleton count={2} />}
                  </h5>
                </a>
              </div>
              <small className="text-muted my-1 fw-semibold">{1 ? "Coach" : <Skeleton />}</small>
              <div className="d-flex gap-2 align-cardDetails-center ">
                <div className="rounded-circle overflow-hidden my-2">
                  {cardDetail?.coach_image ? <img
                    src={cardDetail?.coach_image}
                    alt="profile"
                    style={{ width: "45px", height: "43px" }}
                  /> :
                    <Skeleton circle width={45} height={45} />
                  }
                </div>
                <div>

                  <p className="mb-0 fw-bold my-3">{cardDetail?.coach_name || <Skeleton width={150} />}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cardDetail ?
        <div className="d-flex flex-row-reverse py-2 border-bottom border-dark">
          {/* <div >
          <Button
            btnType="whiteButton"
            name={<img src={Btn} style={{ height: "15px", width: "18px" }} />}
          />
        </div> */}
          <div className="mx-2" onClick={cardDetail?.status ? download : ''}>
            <Button name={cardDetail?.status ? "Download Certificate" : "Awaiting Certificate"} />
          </div>
        </div> : <Skeleton height={40} />
      }
    </div>
  );
};

export default CertificateCard;
