import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../components/common/button/Button';
import '../../assets/scss/pages/changePassword/changePassword.scss'
import "../../assets/scss/components/input/input.scss";
import "../../assets/scss/components/signIn/signIn.scss";
import Account from '../account/Account';
import PasswordStrength from '../../components/common/passwordStrength/PasswordStrength';

import eyeoff from "../../assets/images/Auth/eyeoff.svg";
import eyeon from "../../assets/images/Auth/eyeon.svg";
import { changePasswordService } from '../../services/user.service';
import { selectUserDetails, setToken, setUserDetail } from '../../redux/userSlice';
import { logout } from '../../services/auth.service';
import { removeUserSession } from '../../utils/Auth.Service';

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetail = useSelector(selectUserDetails);

    const [isLoading, setIsLoading] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const { register, watch, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
        mode: "onBlur"
    });
    const watchNewPassword = watch("new_password")

    function printValues(obj) {
        for (var key in obj) {
            if (typeof obj[key] === "object") {
                printValues(obj[key]);
            } else {
                toast.error(obj[key] || "Something went wrong")
            }
        }
    }

    const handleLogoutClick = async () => {
        await logout();
        dispatch(setToken(null));
        dispatch(setUserDetail(null))
        removeUserSession();
        navigate("/login");
    };

    /**
        * @function onSubmit
        * @param (formdata)
        * @description This function is used to change password
    */
    const onSubmit = async (formdata) => {
        setIsLoading(true);
        const data = new FormData()
        data.append('email', userDetail.email)
        data.append('current_password', formdata.current_password)
        data.append('new_password', formdata.new_password)
        data.append('confirm_password', formdata.confirm_password)
        const result = await changePasswordService(data);
        if (result?.response?.data?.errors) {
            printValues(result?.response?.data?.errors)
        } else if (result?.response?.data?.status === "error") {
            toast.error(result?.response?.data?.message || "Something went wrong")
        } else {
            handleLogoutClick()
            toast.success("Your password updated successfully");
        }
        setIsLoading(false);
    }

    return <Account>
        <div className=' pass-container d-flex  justify-content-center '>
            <div className=' main-pass-container  '>
                <h4>Change Password</h4>
                <div className='password-container shadow px-3 my-3 rounded'>
                    <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                        <div className='py-1'>
                            <small className='fw-semibold'>Current Password</small>
                            <div className="input-container">
                                <div className="input-password-group">
                                    <input
                                        type={showCurrentPassword ? "text" : "password"}
                                        className={`form-control`}
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        placeholder="Enter your password"
                                        {...register('current_password', {
                                            required: "Current Password is required"
                                        })}
                                    />
                                    <div className="input-password-icon-container">
                                        {!showCurrentPassword ? (
                                            <img
                                                src={eyeoff}
                                                alt="eyeoffIcon"
                                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                            />
                                        ) : (
                                            <img
                                                src={eyeon}
                                                alt="eyeonIcon"
                                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {errors.current_password && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                {errors.current_password.message}
                            </p>}
                        </div>
                        <div className='py-1'>
                            <small className='fw-semibold'>New Password</small>
                            <div className="input-container">
                                <div className="input-password-group">
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        className={`form-control`}
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        placeholder="Enter your password"
                                        {...register('new_password', {
                                            required: "New Password is required",
                                            pattern: {
                                                value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                                                message: "Enter a valid password"
                                            }
                                        })}
                                    />
                                    <div className="input-password-icon-container">
                                        {!showNewPassword ? (
                                            <img
                                                src={eyeoff}
                                                alt="eyeoffIcon"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                            />
                                        ) : (
                                            <img
                                                src={eyeon}
                                                alt="eyeonIcon"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {errors.new_password && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                {errors.new_password.message}
                            </p>}
                            {watchNewPassword && (
                                <PasswordStrength password={watchNewPassword} />
                            )}
                        </div>
                        <div className='py-1'>
                            <small className='fw-semibold'>Confirm Password</small>
                            <div className="input-container">
                                <div className="input-password-group">
                                    <input
                                        type={showConfirmNewPassword ? "text" : "password"}
                                        className={`form-control`}
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        placeholder="Enter your password"
                                        {...register('confirm_password', {
                                            required: "Confirm Password is required"
                                        })}
                                    />
                                    <div className="input-password-icon-container">
                                        {!showConfirmNewPassword ? (
                                            <img
                                                src={eyeoff}
                                                alt="eyeoffIcon"
                                                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                            />
                                        ) : (
                                            <img
                                                src={eyeon}
                                                alt="eyeonIcon"
                                                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {errors.confirm_password && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                {errors.confirm_password.message}
                            </p>}
                        </div>
                        <div className='py-2 pass-btn'>
                            <Button
                                name={`${isLoading ? "Loading..." : "Save"}`}
                                type={'submit'}
                                disable={!isDirty || !isValid}
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </Account>
};

export default ChangePassword;