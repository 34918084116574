import axios from "axios";

/**
    * @function paymentPage
    * @param
    * @description This function is used to make the payment
*/
export const paymentPageService = async () => {
    try {
        const result = await axios.post(`user/learn-and-earn/subscribe/payment`, {});
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function applyScratchCodeService
    * @param promo_code
    * @description This function is used to apply promo code
*/
export const applyScratchCodeService = async (scratch_code) => {
    
    try {
        const result = await axios.post(`user/learn-and-earn/subscribe/scratch-code`, {
            scratch_code
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function subscriptionStatus
    * @param
    * @description This function is used to fetch the status of user's subscription
*/
export const subscriptionStatusLearnAndEarn = async () => {
    try {
        const result = await axios.get(`user/learn-and-earn/subscription-status`, {});
        return result;
    } catch (error) {
        return error;
    }
};