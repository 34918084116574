import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//importing components
import VerticalCard from "../../common/cards/courseCards/VerticalCard";
import Button from "../../common/button/Button";

//importing style
import "../../../assets/scss/components/course/onferenceClassListing/onferenceClassListing.scss";
import SiteSlider from "../../common/slider/SiteSlider";
import {
  selectClassTypes,
  selectOnFerenceClasses,
  setOnFerenceClasses,
} from "../../../redux/classSlice";
import { getClassService } from "../../../services/dashboard.service";

const OnFerenceClassListing = () => {
  const dispatch = useDispatch();

  const getSlug = useSelector(selectClassTypes)?.filter((item) => {
    return item?.name?.toLowerCase() === "onference";
  });
  useEffect(() => {
    const fetchOnFerenceClasses = async () => {
      try {
        const result = await getClassService({ filter: getSlug[0]?.slug });
        dispatch(setOnFerenceClasses(result?.data?.classes?.data));
      } catch (error) {
        console.log(error);
      }
    };
    if (getSlug[0]?.slug) {
      fetchOnFerenceClasses();
    }
  }, [getSlug[0]?.slug]);

  const onFerenceClasses = useSelector(selectOnFerenceClasses)
  // const onFerenceClasses = useSelector(selectOnFerenceClasses);
  return (
    <>
      {onFerenceClasses?.length > 0 && (
        <div className="OnFerenceClassListing-main-container">
          <h2 className="fw-bold fs-4 mb-1 mx-2">OnFerence</h2>
          <div className="d-flex align-items-center justify-content-between flex-wrap mx-2">
            <p className="OnFerenceClassListing-main-text">
              Engage in dynamic LIVE sharing conferences.
            </p>
            <Link to={`/classes/${getSlug[0]?.slug}`}>
              <div
                className="my-2"
              >
                <Button name="Explore All Courses" btnType="whiteButton" />
              </div>
            </Link>
          </div>
          <div className="mt-2">
            <SiteSlider
              cardType="vertical"
              noOfItems={onFerenceClasses?.length}
            >
              {onFerenceClasses?.map((item, index) => {
                return (
                  <div className="m-2" key={`onference-${index}`}>
                    <VerticalCard cardContent={item} cardType="onFerenceCard" />
                  </div>
                );
              })}
            </SiteSlider>
          </div>
        </div>
      )}
    </>
  );
};

export default OnFerenceClassListing;
