import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Custom Component
import Account from '../account/Account';
import Button from '../../components/common/button/Button';

// Custom CSS
import '../../assets/scss/pages/RedeemGiftCard/RedeemGiftCard.scss';

import { Tooltip, OverlayTrigger, } from 'react-bootstrap';

import { IoIosInformationCircleOutline } from "react-icons/io";

// API Service
import { redeemScratchCodeService } from "../../services/redeem.service";
import { applyScratchCodeService } from '../../services/learnAndEarn.service';



const RedeemGiftCard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state;

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
        mode: "onBlur"
    });

    /**
        * @function onSubmit
        * @param (formdata)
        * @description This function is used to apply scratch code
    */
    const onSubmit = async (formdata) => {
        let result
        if (state === 'learn-and-earn') {
            result = await applyScratchCodeService({
                scratch_code: formdata.scratch_code
            });

        } else {
            result = await redeemScratchCodeService({
                scratch_code: formdata.scratch_code
            });
        }

        if (result?.data?.status === "success") {
            toast.success(result?.data?.message || "Subscription extended successfully.")
            navigate("/account/subscription")
        } else if (result?.data?.message) {
            toast.error(result?.data?.message || "Something went wrong")
        } else if (result?.response?.data?.message) {
            toast.error(result?.response?.data?.message || "Something went wrong")
        }

    }

    return <Account>
        <div className="max-w-redeem d-flex  justify-content-center">
            <div>
                <h4>Redeem Gift Card</h4>
                <div className='main-bg-div shadow rounded'>
                    <div className='shadow-sm rounded p-3 py-4 my-3 bg-white'>
                        <div className='gift-div my-3'>
                            <h4 className='text-redeem'>Redeem Your Scratch code</h4>
                            <small >Enter the code on the back of your scratch code</small>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='py-3 voucher-input-container position-relative'>
                                    {/* <small className='fw-semibold py-2'>Enter your Scratch code </small> */}
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Here"
                                        {...register('scratch_code', {
                                            required: "Scratch code is required"
                                        })}
                                    />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                The voucher code can be found on your scratch card. Scratch the covered section to reveal your 10 digit voucher code.
                                            </Tooltip>
                                        }
                                    >
                                        <div className="info-icon">
                                            <IoIosInformationCircleOutline size={20} />
                                        </div>
                                    </OverlayTrigger>

                                </div>
                                {errors.scratch_code && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errors.scratch_code.message}
                                </p>}
                                <Button
                                    type="submit"
                                    name="Redeem Now"
                                    disable={!isDirty || !isValid}
                                />
                            </Form>
                            <div className='text-center my-2'>
                                <small>Need Help with Redemptions? </small>
                                <a href="https://about.reskills.com/contact-reskills/" className='fw-semibold text-primary text-decoration-none'>
                                    Contact Us
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </Account>
};

export default RedeemGiftCard;