import { useState } from "react";

import ReactPaginate from "react-paginate";
import TransactionHistoryCard from "../cards/TransactionHistory/TransactionHistoryCard";

//importing styles
import "../../../assets/scss/components/paginate/paginate.scss";

function Items({ currentItems }) {
  return (
    <>
      <div className="card-container">
        {currentItems?.map((item ,index) => {
          return (
            <div>
              <TransactionHistoryCard item={item} index={index} />
            </div>
          );
        })}
      </div>
    </>
  );
}


const CardPaginate = ({items, totalItem }) => {
 
  const itemsPerPage = 8;
  const pageCount = Math.ceil(totalItem / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(0);
  
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = items.slice(offset, offset + itemsPerPage);

 
  return (
    <>
      <Items currentItems={currentItems} />
      <div className={currentPage == pageCount - 1 && currentPage === 0 ?"d-flex justify-content-center" :''}>
        <ReactPaginate
          breakLabel="..."
          nextLabel={currentPage === pageCount - 1 ? null : "Next"}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={currentPage === 0 ? null : "Previous"}
          renderOnZeroPageCount={null}
          className="paginationComponent"
          containerClassName={"pagination"}
          previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
          nextClassName={currentPage === pageCount - 1 ? " hide-next-arrow" : "next"}
        />
      </div>
      
    </>
  );
};

export default CardPaginate;
