import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
//importing components
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";

//importing images
import siteLogo from "../../assets/images/newSiteLogo.png";
import icon from "../../assets/images/Auth/sign-up-icon1.svg";

//importing styles
import "../../assets/scss/pages/auth/signUp/signUp.scss";
import Dropdown from "../../components/common/dropdown/Dropdown";
import PasswordStrength from "../../components/common/passwordStrength/PasswordStrength";
import InputWithPhoneCode from "../../components/common/input/InputWithPhoneCode";

//import services
import { signUpUser } from "../../services/auth.service";
import { setUserSession, updateUserSession } from "../../utils/Auth.Service";
import { useDispatch, useSelector } from "react-redux";
import { selectCountryList, setToken } from "../../redux/userSlice";
import { useSearchParams } from "react-router-dom";


const SignUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const countryList = useSelector(selectCountryList);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState();
    // Initialize useSearchParams
    const [searchParams] = useSearchParams();

    // Get the value of referral_code
    const referralCode = searchParams.get('referral_code');

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
        news_letter_subscription: 0,
        privacy_policy: false,
        captcha_response: "",
        referral_code: referralCode,
    });
    const [errorMessage, setErrorMessage] = useState({});

    /**
        * @function signUpUser
        * @param { first_name, last_name, email, phone_number, country_code, country_id, password, password_confirmation,captcha_response }
        * @description This function is used to register user
    */
    const handleSignUpUser = async () => {
        setIsLoading(true);
        if (formData) {
            const result = await signUpUser({
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                email: formData?.email,
                phone_number: formData?.phone_number,
                country_code: countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.isd_code,
                country_id: countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.id,
                password: formData?.password,
                password_confirmation: formData?.password_confirmation,
                news_letter_subscription: formData?.news_letter_subscription,
                privacy_policy: formData?.privacy_policy,
                captcha_response: formData?.captcha_response,
                referral_code: formData?.referral_code,
            });
            if (result?.data?.status === "success") {
                const token = result?.data?.user?.verification_token;
                const user = result?.data?.user?.id;
                const tokens = {
                    tokenAccess: token,
                };
                // dispatch(setToken(tokens?.tokenAccess))
                // setUserSession(tokens, user);
                updateUserSession(user)
                navigate("/otp-verification", {
                    state: {
                        type: "register",
                        email: formData?.email,
                        first_name: formData?.first_name
                    },
                });
            }
            if (result?.response?.data?.errors) {
                setErrorMessage(result?.response?.data?.errors);
            }
        }
        setIsLoading(false);
    };

    /**
        * @function handleOnChange
        * @param { non }
        * @description This function is used to set the input values
    */
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /**
        * @function handleCheckBox
        * @param { non }
        * @description This function is used to set the checkbox values
    */
    const handleCheckBox = (e) => {
        const { name, checked } = e.target;
        if (name === "news_letter_subscription") {
            setFormData({
                ...formData,
                [name]: checked === true ? "1" : "0",
            });
        } else {
            setFormData({
                ...formData,
                [name]: checked,
            });
        }
    };

    /**
        * @function onChange
        * @param {  recaptcha token }
        * @description This function is used to set generated recaptcha token
    */
    function onChange(value) {
        setFormData({
            ...formData,
            captcha_response: value,
        });
    }

    return (
        <div className="custom-container max-w-home">
            <div className="signUp-main-container d-flex">
                <div className="signUp-left-container">
                    <div className="text-center">
                        <Link to="/">
                            <img src={siteLogo} alt="siteLogo" className="mb-4" />
                        </Link>

                        <h2 className="fw-bold fs-3">Sign Up</h2>
                    </div>
                    <div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.first_name && "errorColor"}   fs-6`}>
                                First Name <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.first_name && "error-border"} `}
                                name="first_name"
                                placeholder="Enter first name"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.first_name && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.first_name[0]}
                                </p>
                            )}
                            <p className="m-0 text-secondary fs-6">
                                This name is non-changeable, and it will be the name mentioned on
                                certificates
                            </p>
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.last_name && "errorColor"}   fs-6`}>
                                Last Name <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.last_name && "error-border"} `}
                                name="last_name"
                                placeholder="Enter last name"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.last_name && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.last_name[0]}
                                </p>
                            )}
                            <p className="m-0 text-secondary fs-6">
                                This name is non-changeable, and it will be the name mentioned on
                                certificates
                            </p>
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.email && "errorColor"}  fs-6`}>
                                Email <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.email && "error-border"} `}
                                name="email"
                                placeholder="Enter your email"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.email && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.email[0]}
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.country_code && "errorColor"} fs-6`}>
                                Country <span className="text-danger">*</span>
                            </label>
                            <Dropdown
                                options={countryList}
                                defaultOption="Select the country"
                                setSelectedCountry={setSelectedCountry}
                            />

                            {errorMessage?.country_code && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    The Country field is required
                                </p>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className={`${errorMessage?.phone_number && "errorColor"} fs-6`}>
                                Phone Number <span className="text-danger">*</span>
                            </label>
                            <InputWithPhoneCode
                                name="phone_number"
                                phoneCode={
                                    selectedCountry && countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.isd_code
                                }
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.phone_number && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.phone_number[0]}
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.password && "errorColor"} fs-6`}>
                                Create Password <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.password && "error-border"} `}
                                name="password"
                                placeholder="Enter your password"
                                type="password"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.password && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.password[0]}
                                </p>
                            )}
                            {formData?.password && (
                                <PasswordStrength password={formData?.password} />
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="fs-6">Confirm Password <span className="text-danger">*</span></label>
                            <Input
                                name="password_confirmation"
                                placeholder="Enter your password"
                                type="password"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.password_confirmation && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.password_confirmation[0]}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 mt-3">
                        <div className="form-check d-flex gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="news_letter_subscription"
                                onChange={handleCheckBox}
                            />
                            <p className="m-0 fs-6 fw-normal">Keep me updated on new contents and exciting events</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check d-flex gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="privacy_policy"
                                onChange={handleCheckBox}
                            />
                            <p className="m-0 fs-6 fw-normal">
                                I agree with the <Link className="text-decoration-none" to="/terms">Terms and Conditions</Link> and <Link className="text-decoration-none" to="/privacy"> Privacy Policy</Link>
                            </p>
                        </div>
                        {errorMessage?.privacy_policy && (
                            <p style={{ fontSize: "13px", color: "#f04438" }}>
                                Please check the terms and privacy policy box.
                            </p>
                        )}
                    </div>
                    <div className="my-3">
                        <ReCAPTCHA
                            sitekey="6LcxA54pAAAAAG756fqqQSy621XWQ3wk5CTgDvYE"
                            onChange={onChange}
                        />
                    </div>
                    <Button
                        name={`${isLoading ? "Loading..." : "Register Now"}`}
                        onClick={handleSignUpUser}
                        disable={formData?.captcha_response ? false : true}
                    />
                    <div className="mt-3 text-center">
                        <p className="text-secondary fs-6">
                            Already have an account?{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={() => navigate("/login")}
                            >
                                Sign in
                            </span>
                        </p>
                    </div>
                </div>
                <div className="signUp-right-container">
                    <div className="d-flex align-items-center mb-3">
                        <h3 className="fw-bold mb-0 me-2">Why learn with</h3>
                        <Link to='/'>
                            <img src={siteLogo} alt="siteLogo" />
                        </Link>
                    </div>
                    <div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">ALL IN 1 ACCESS</h5>
                                <p className="text-secondary fs-6">
                                    Full access to learning contents and features <br /> with no
                                    hidden costs.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">FAST AND IMPACTFUL LEARNING</h5>
                                <p className="text-secondary fs-6">
                                    Fast and impactful learning with diverse, high<br /> quality learning content.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">365 DAYS NON-STOP LIVE CLASSES</h5>
                                <p className="text-secondary fs-6">
                                    Master new skillset through daily interactive LIVE<br /> classes
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">GLOBAL MASTER COACHES</h5>
                                <p className="text-secondary fs-6">
                                    Learn from esteemed global coaches and <br />industry experts
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">EXCLUSIVE EVENTS</h5>
                                <p className="text-secondary fs-6">
                                    Exclusive access to special learning events<br /> unavailable elsewhere
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <div>
                                <img src={icon} alt="icon" />
                                {/* <hr className="vertical-line" /> */}
                            </div>
                            <div>
                                <h5 className="fw-bold">GLOBALLY ACCREDITED <br />CERTIFICATION</h5>
                                <p className="text-secondary fs-6">
                                    Boost your career by earning globally<br /> accredited certification.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-5 fs-6 mx-3">
                © Reskills 2021-2024
            </div>
        </div>
    );
}

export default SignUp;
