import { useState } from "react";
import { Modal } from "react-bootstrap";

//import react rating
import { Rating } from "react-simple-star-rating";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/recordingAlertModal.scss";
import { rateReviewService } from "../../services/class.service";

const RateNReviewModal = ({ id, show, setShow, setReviewSuccessModal }) => {
  const [review, setReview] = useState(0);
  const [rating, setRating] = useState();
  const handleClose = () => setShow(false);
  const [error, setError] = useState("Review cannot be empty");
  const [error2, setError2] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1000) {
      setError("Review cannot exceed 1000 characters");
    } else if (inputValue.trim() === "") {
      setError("Review cannot be empty");
    } else if (inputValue.length < 30) {
      setReview(inputValue);
      setError2("Review must be atleast 30 Characters long");
    }
    else {
      setReview(inputValue);
      setError("");
      setError2('')
    }

  };

  const handleSubmit = async () => {

    if (review.length > 1000) {
      setError("Review cannot exceed 1000 characters");
    } else if (review.trim() === "") {
      setError("Review cannot be empty");
    } else if (review.length < 30) {
      setError2("Review must be atleast 30 characters long");
    }
    await rateReviewService({
      classId: id,
      rating: rating,
      review: review,
    });
    handleClose();
    setReviewSuccessModal(true);
  };
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="py-4 px-3">
        <Modal.Title>
          <h5 className="fw-bolder">Your opinion matter to us!</h5>
        </Modal.Title>
        <div className="my-4">
          <p className={rating === undefined ? 'text-danger mb-1 fs-6 fw-bold' : "mb-1 fs-6 fw-bold"}>How would you rate the Live session? </p>
          <div className="w-100">
            <Rating initialValue={0} size={50} onClick={handleRating} />
          </div>
        </div>
        <div>
          <p className={!!error ? 'text-danger mb-1 fs-6 fw-bold' : "mb-1 fs-6 fw-bold"}>What is your review or feedback?</p>
          <textarea
            className="w-100 border border-secondary rounded p-2"
            placeholder="Enter here"
            onChange={handleInputChange}
          />
          {error2 && <p className="text-danger">{error2}</p>}
        </div>
        <div className="fs-7">{review.length || 0}/1000</div>
        <div className="d-flex align-items-center justify-content-end gap-2 mt-5">
          <div>
            <Button
              btnType="whiteButton"
              name="Back to home"
              onClick={handleClose}
            />
          </div>
          <div
            className="btn-container"
            style={{ width: "70px" }}

          >
            <Button name="Submit" disable={!!error || !!error2 || rating === undefined} onClick={() => handleSubmit()} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RateNReviewModal;
