import React from 'react';
import moment from 'moment';
import '../../../../assets/scss/pages/TransactionHistory/TransactionHistory.scss'
import Export from '../../../../assets/images/TransactionHistory/export.png'


const TransactionHistoryCard = ({item,index}) => {
    return (
        <div className='my-3' key={`transaction-${index}`}>
                            <div className='row d-flex shadow-sm rounded  p-3 border border-light content-between w-100'>
                                <div className='tran-container col-9 d-flex  justify-content-between'>
                                    <div className=' invoice-div pt-2 '>
                                        <small className='text-mute'>Invoice</small>
                                        <div className='fw-semibold text-primary'>{item?.invoice_no}</div>
                                    </div>
                                    <div className='fw-semibold pt-2 pt-1 mt-2-th'>
                                        ${item?.amount}
                                    </div>
                                    <div className='pt-2 pt-1 mt-2-th'>
                                        {moment(item?.payment_date)?.format("D/MMMM/YYYY")}
                                    </div>
                                </div>
                                <div className='tran-container pt-2 mt-2-th col-2'>
                                    <div className='Sucess-btn px-2 text-white'>{item?.payment_status}</div>
                                    {/* <div className='wht-btn  d-flex pt-2'>
                                        <button className='btn btn-outline-secondary  bg-white btn-sm py-1 px-3 rounded fw-bold wht-btn'>Reciept
                                            <img src={Export} alt='' className='mx-2' style={{ width: '13px', height: '13px' }} /></button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
    );
};

export default TransactionHistoryCard;