import axios from "axios";

/**
    * @function transactionHistoryService
    * @param 
    * @description This function is used to get the list of transaction history
*/
export const transactionHistoryService = async () => {
    try {
        const result = await axios.get(`user/transaction-history?per_page=10`);
        return result;
    } catch (error) {
        return error;
    }
};