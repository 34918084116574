//import react rating
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";

//importing images
import Image from "../../image";
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.svg";
import alternateBanner from "../../../../assets/images/Cards/VerticalCard/altClassBanner.svg";
// import avatar from "../../../../assets/images/Cards/HorizentalCard/Avatar.svg";
import avatar from "../../../../assets/images/Auth/avatar2.svg";

import { useState } from "react";
//importing styles
import "../../../../assets/scss/components/card/verticalCardForLearnAnytime/verticalCardForLearnAnytime.scss";

const VerticalCardForLearnAnytime = ({ cardContent, cardType }) => {


  return (
    <div className="VerticalCardForLearnAnytime-main-container cursor-pointer ">
      <div className="card border-0 shadow-sm mb-4 m-2 min-height-ver">
        <img
          className="card-img-top"
          src={
            cardContent?.images
              ? cardContent?.images[0]?.image
              : cardContent?.thumb_image
          }
          alt="Card cap"
        />
        <div className="card-body">
          <div className="d-flex align-items-centre gap-3">
            <p className="text-primary fs-6">{cardContent?.class_type?.name}</p>
            <span>.</span>
            <p className="text-primary fs-6">{cardContent?.language?.name}</p>
            <span>.</span>

            <p className="text-primary fs-6 text-truncate ">{cardContent?.category?.name}</p>
          </div>
          <div className="position-relative">
            <h6 className="card-title fw-bold mb-1">{cardContent?.title}</h6>
          </div>
          <div className="d-flex align-items-center gap-1">
            <Rating
              initialValue={cardContent?.average_rating}
              value={cardContent?.average_rating}
              customIcons={ratingIcon}
              readonly={true}
              size={20}
              allowFraction={true}
            />
            <p className="mb-0 text-secondary">5.0</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 ">
            {cardContent?.coaches?.map((item) => {
              return (
                <div className="d-flex gap-2 align-items-center" key={`coach-${item?.id}`}>
                  <div>

                  <Image
                                            src={item?.avatar}
                                            alt="profile"
                                            style={{ width: "45px", height: "45px" }}
                                            defaultImage={avatar}
                                        />
                  </div>
                  <div>
                    <Link className='text-dark text-decoration-none' to={`coach/${item?.id}`}>
                      <p className="mb-0 fw-bold">{item?.full_name}</p>
                    </Link>
                    {/* <p className="mb-0 fs-6 text-secondary">It & Software</p> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalCardForLearnAnytime;
