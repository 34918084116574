import banner from "./bannerSlice";
import classes from "./classSlice";
import user from "./userSlice";
import stats from "./statsService";
import review from "./reviewSlice";
import category from "./categorySlice";
import subscription from "./subscriptionSlice"
import learnAndEarnSubscription from './learnAndEarnSubscriptionSlice'
import transactionHistoryList from './transactionHistorySlice'
import certificateList from "./certificateSlice";
import coachList from "./coachSlice";
const rootReducer = {
  banner,
  user,
  classes,
  stats,
  review,
  category,
  subscription,
  learnAndEarnSubscription,
  transactionHistoryList,
  certificateList,
  coachList
};

export default rootReducer;
