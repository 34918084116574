import { useState } from "react";
import { useNavigate } from "react-router-dom";
//importing styles
import "../../assets/scss/components/forgetPassword/forgetPassword.scss";

//importing icon
import icon from "../../assets/images/Auth/ForgetPassIcon.svg";

//importing components
import Button from "../../components/common/button/Button";
import Input from "../../components/common/input/Input";

//importing sevices
import { forgetPassword } from "../../services/auth.service";

const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  /**
   * @function handleChange
   * @param {value}
   * @description This function is used to set email
   */
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  /**
   * @function handleVerify
   * @param {email}
   * @description This function is used to send OTP to user-phone for reset forget-password
   */

  const handleVerify = async () => {
    setIsLoading(true);
    const result = await forgetPassword({
      email: email,
    });
    if (result?.response?.data?.errors) {
      setError(true);
    }
    if (result?.data?.status === "success") {
      // setUserSession(tokens, user);
      navigate("/otp-verification", {
        state: {
          type: "forget_password",
          email: email,
          otp: result?.data?.user?.otp,
        },
      });
    }
    setIsLoading(false);
  };
  return (
    <div className="forget-password-container w-25 mx-auto mt-5">
      <div className="text-center">
        <img src={icon} alt="siteLogo" className="mb-4" />
        <h2 className="fw-bold fs-3 mb-2">Forgot Password?</h2>
        <p className="mb-4 text-secondary fs-6">
          No worries, it happens to the best of us. Just enter the email address
          linked with your account
        </p>
      </div>
      <div className="mb-2">
        <label className={`${error && "error-class"} fs-6`}>Email</label>
        <Input
          errorClass={error && "error-border"}
          placeholder="Enter your email"
          onChange={(e) => handleChange(e)}
        />
      </div>
      {error && (
        <p style={{ fontSize: "13px", color: "#f04438" }}>
          Please enter a valid email
        </p>
      )}
      {error && (
        <div>
          <p className="fs-6 fw-bold my-2">Want to use this email address?</p>
          <p className="fs-6 text-secondary">
            Register for a new account on ReSkills, Click here <br /> to
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigate("/sign-up")}
            >
              Sign up
            </span>{" "}
            .
          </p>
        </div>
      )}
      <div onClick={() => handleVerify()}>
        <Button
          name={`${isLoading ? "Loading..." : "Get Verification Code"}`}
        />
      </div>
      <div className="mt-3 text-center">
        <p className="text-secondary fs-6">
          Remember Password?{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
};

export default ForgetPassword;
