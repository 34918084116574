import React from 'react';
import Button from '../../components/common/button/Button';
import SiteSlider from '../../components/common/slider/SiteSlider';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { selectClassList } from '../../redux/classSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';


const UpComingClasses = () => {

    const Classes = useSelector(selectClassList);
    const { slug } = useParams();


    const currentDate = new Date();
    const currentDateString = moment
        .parseZone(currentDate)
        .local()
        .format('YYYYMMDD');

    const todayClasses = Classes?.filter((item) => {
        const classStartDate = moment
            .parseZone(item?.start_date_time)
            .local()
            .format('YYYYMMDD');

        return classStartDate > currentDateString;
    });
    const getClassTitle = () => {
        switch (slug) {
            case 'onstage':
                return 'Upcoming OnStage'
            case 'onference':
                return 'Upcoming OnFerence'
            default:
                return 'Today’s LIVE sessions'
        }
    }

    const containerClass = todayClasses?.length === 1 ? 'today-centered-content' : '';
    return (
        <>
            {todayClasses?.length > 0 && (
                <div className="">
                    <h2 className="liveCourse-main-title fw-bold fs-4 mb-4 mx-3 my-4">
                        {getClassTitle()}
                    </h2>
                    <div className={containerClass}>
                        <SiteSlider
                            cardType="horizental"
                            noOfItems={todayClasses?.length}
                        >
                            {todayClasses?.map((item) => (
                                <div className="position-relative py-3 mx-2 " key={item.id}>
                                    <div>
                                        <img src={item.images ? item.images[0]?.image : item?.thumb_image} className="w-100 border-radius todays-classes" alt={`${item?.name}`} />
                                        <div className="position-absolute bottom-0 start-50 translate-middle mb-3">
                                            <Link className="text-dark text-decoration-none" to={`/class/${item.slug}`}>
                                                <Button
                                                    name="Register Now"
                                                    btnType="registerNow"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SiteSlider>
                    </div>
                </div>
            )}
        </>
    );
};

export default UpComingClasses;